import request from '@/utils/request'
import constant from '@/constant'
//异常上报messageReport
function pageList(formData) {
  return request.post(constant.serverUrl + "/messageReport/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/messageReport/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/messageReport/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/messageReport/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/messageReport/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/messageReport/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/messageReport/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function list() {
  return request.post(constant.serverUrl + "/messageReport/list");
}

export default {
  create, edit, add, update, remove, batchRemove, pageList, list
}